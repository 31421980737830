import { DialogProps } from '@mui/material/Dialog';
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ForestIcon from '@mui/icons-material/Forest';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import { useState } from 'react';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function HowItWorksDialog(props: DialogProps) {
  const [first, setFirst] = useState(true);
  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (props.onClose !== undefined) {
      props.onClose(event, "backdropClick")
    }
    setFirst(false);
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={props.open}
        onClose={handleClose}
        TransitionComponent={props.TransitionComponent}
      >
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'primary.dark' }}>
            <ForestIcon />
          </Avatar>
          {first && <Typography component="h1" variant="h6">
            Since this is your first quote
          </Typography>}
            <Typography
              component="h1"
              color="inherit"
              noWrap
              sx={{ mt: 1, fontSize: 20, fontWeight: 900, flexGrow: 1 }}
            >
              Let's quickly review how it works
            </Typography>
          <Typography sx={{mt:3}} component="h1" variant="subtitle1">
            1. First we'll walk you through how to capture photos and videos of each tree on your property to make sure you get an accurate estimate.
          </Typography>
          <Typography sx={{mt:3}} component="h1" variant="subtitle1">
2. Then, with one click in our app, you'll submit your digital quote to our tree trimming experts. We'll analyze the job and text an estimate back within 24 hours.
          </Typography>
          <Typography sx={{mt:3}} component="h1" variant="subtitle1">
3. Review the quote on your phone. If you like the price, click to accept and we'll contact you to make all the scheduling arrangements. 
          </Typography>
          <Typography sx={{my:3}} component="h1" variant="subtitle1">
That's it!
          </Typography>
            <Button
              onClick={e => handleClose(e)}
              type="submit"
              color='warning'
              fullWidth
              variant="contained"
            >
              Done
            </Button>
          </Box>
      </Container>
      </Dialog>
    </React.Fragment>
  );
}
//send us your available timeslots and we'll text you a confirmed tree trimming appointment.
