import * as React from 'react';
import Fab from '@mui/material/Fab';
import Slide from '@mui/material/Slide';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import EditIcon from '@mui/icons-material/Edit';
import ForestIcon from '@mui/icons-material/Forest';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import { Transition } from 'react-transition-group';
import Draggable from 'react-draggable';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useState, useRef, useEffect } from 'react';

interface FabBarProps {
  on1(event: React.MouseEvent<HTMLButtonElement>): void;
  on2(event: React.MouseEvent<HTMLButtonElement>): void;
  on3(event: React.MouseEvent<HTMLButtonElement>): void;
  on4(event: React.MouseEvent<HTMLButtonElement>): void;
  on5(event: React.MouseEvent<HTMLButtonElement>): void;
}

var detailsTimer = 0;
export default function FabBar(props: FabBarProps) {
  const [fabDetails, setFabDetails] = useState(false);
  const [about, setAbout] = useState(true);
  const [zIndex, setZIndex] = useState(200);
  const nodeRef = React.useRef(null);

  const toggleFab = (open: boolean) => {
    setAbout(false);
    setFabDetails(open);
    if (open) {
      window.clearTimeout(detailsTimer);
      detailsTimer = window.setTimeout(() => setFabDetails(false), 3000);
    }
  }

  const StyledBox = styled(Box)(({ theme }) => ({
    top: '90vh',
    padding: '10px 10px 10px 10px',
    position: 'fixed',
    backgroundColor: 'rgba(234, 240, 234,  0.75)',
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'row',
    flexGrow: 1,
    borderRadius: '45px',
    borderColor: 'grey',
    borderWidth: '1px',
    borderStyle: 'solid',
    zIndex: zIndex,
  }));

  return (
    <React.Fragment>
      <ClickAwayListener onClickAway={()=>setAbout(false)}>
        <div>
        <Draggable nodeRef={nodeRef} handle=".drag-handle" axis="y">

  <Tooltip placement="top" open={about} title="The remoteQuote toolbar has shortcuts for things you need to make a quote, tap the info button to learn more">
          <StyledBox ref={nodeRef}>
  <Tooltip placement="top" open={fabDetails} title="Drag">
            <Button color="inherit" variant='text' className="drag-handle">
              <DragHandleIcon/>
            </Button>
            </Tooltip>
  <Tooltip placement="top" open={fabDetails} title="Add Tree">
            <Button sx={{backgroundColor:'white', borderRadius: 6}} variant="outlined" color="success" onClick={props.on1}>
              <ForestIcon />
            </Button>
            </Tooltip>
  <Tooltip placement="top" open={fabDetails} title="Add Note">
            <Button sx={{backgroundColor:'white', borderRadius: 6, ml:1}} variant="outlined" color="warning" onClick={props.on2}>
              <EditIcon/>
            </Button>
            </Tooltip>
  <Tooltip placement="top" open={fabDetails} title="Done">
            <Button  sx={{backgroundColor:'white', borderRadius: 6, ml:1}}variant="outlined" color="secondary" onClick={props.on3}>
              <SendToMobileIcon/>
            </Button>
            </Tooltip>
            <Box sx={{width:'100%', mt:1}}/>
            <Button color="inherit" onClick={()=>toggleFab(!fabDetails)} variant='text'>
              <InfoIcon/>
            </Button>
          </StyledBox>
        </Tooltip>
      </Draggable>
      </div>
      </ClickAwayListener>
    </React.Fragment>
  );
}
