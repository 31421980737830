import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Title from './Title';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import {useState} from 'react';

interface NotesProps {
  notes: string;
  variant?: string;
  disabled?: boolean;
  onChange(notes: string): any;
}

export default function Notes(props: NotesProps) {
  const {notes, variant, disabled, onChange} = props;
  const [editMode, setEditMode] = useState(false);

  const updateNotes = (notes: string) => {
    console.log('Notes - updateNotes - ' + notes);
    onChange(notes);
  };

  return (
    <React.Fragment>
       <Box component='div' sx={{width: '100%', borderRadius:8, p:3, pt: '31px', my:3, backgroundImage:`url("https://cdn.hovia.com/app/uploads/green-oak-tree-wallpaper-mural-Plain-820x532.jpg")`, backgroundPositionX: '15%', backgroundPositionY: '75%'}}>
            <Grid container spacing={1} sx={{mb:1}}>
              <Grid item sx={{mb: 2, width: '100%', display:'flex', flexDirection: "row", flexGrow:1, justifyContent: "space-between"}}>
              <Grid xs={8} item>
                <Typography sx={{ml:1, fontWeight: 700, color:'black'}} variant="h5">
                  Add Job Notes
                </Typography>
              </Grid>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={e => setEditMode(!editMode)}
                >
                  Reset
                </Button>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  disabled={disabled}
                  fullWidth
                  multiline
                  minRows={9}
                  maxRows={9}
                  id="notes"
                  label="Notes"
                  name="notes"
                  placeholder="Provide any details about your property that might make the job harder. Do you live on a hill? Do you share a tree with your neighbor?"
                  onChange={e => updateNotes(e.target.value)}
                  value={props.notes}/>
              </Grid>
              <Grid xs={12} sx={{mt:3}} item>
                <Button
                  color="info"
                  variant="contained"
                  fullWidth
                  onClick={e => setEditMode(!editMode)}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
        </Box>
    </React.Fragment>
  );
}
