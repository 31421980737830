import * as React from 'react';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Slide from '@mui/material/Slide';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import CelebrationIcon from '@mui/icons-material/Celebration';
import CloseIcon from '@mui/icons-material/Close';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import BasicInfo, {IBasicInfo} from './BasicInfo';
import { useNavigate, useLocation } from "react-router-dom";
import { useRef, useEffect, useState } from 'react';

interface ISlot {
  time: string;
  open: boolean;
};

interface IDateRow {
  date: string;
  slots: ISlot[];
};

const days = [1,2,3,4,5,6,7];
const slots = [
  {time: '8-10am', open: false},
  {time: '10-12pm', open: false},
  {time: '1-3pm', open: false},
  {time: '3-5pm', open: false},
];
const theDate = new Date();

const defaultSlotMap = days.map((day) => {return {date: new Date(theDate.setDate(theDate.getDate()+1)).toLocaleDateString('en-us', { weekday:"long", month:"short", day:"numeric", timeZone: "America/Chicago"}), slots: slots}});

const defaultBasicInfo = {customerName:'', phoneNumber:'',address:'', email:''};

export default function QuickCreate() {
  const [activeStep, setActiveStep] = useState(0);
  const [slideOut, setSlideOut] = useState(false);
  const [slotMap, setSlotMap] = useState<IDateRow[]>(defaultSlotMap);
  const [basicInfo, setBasicInfo] = useState<IBasicInfo>(defaultBasicInfo);
  const navigate = useNavigate();
  const location = useLocation();
  
  const handleSendQuote = (event: React.MouseEvent<HTMLButtonElement>) => {
        location.state.quote = {created_by: location.state.user_id, basic_info: basicInfo, slotMap: slotMap};
        navigate('/status', location);
   return;
/*
    fetch('/api/trim_quotes', {
      method: 'POST',
      body: JSON.stringify({created_by: 'user_id' in location.state ? location.state.user_id : 'guest', basic_info: quote.basicInfo, extras: quote.extras, trees: quote.trees, notes: quote.notes}),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Sent Quote');
        location.state.quote = {created_by: location.state.user_id, basic_info: quote.basicInfo, extras: quote.extras, trees: quote.trees};
        navigate('/status', location);
      })
      .catch((error) => {
        console.log('Unable to send quote');
        //navigate('/home', {state: {...location.state, quotes:[{created_by: 'john', basic_info: basicInfo, extras: extras, trees: trees}]}});
      });
*/
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const toggleSlot = (rowIndex:number, slotIndex:number) => {
    const newMap = JSON.parse(JSON.stringify(slotMap));
    newMap[rowIndex].slots[slotIndex].open = !newMap[rowIndex].slots[slotIndex].open;
    setSlotMap(newMap);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const updateBasicInfo = (basicInfo: IBasicInfo) => {
    setBasicInfo(basicInfo);
  };

  return (
  <Box>
          <AppBar color="transparent" sx={{position: 'relative'}}>
          <Toolbar sx={{alignItems:'start'}}>
            <Typography
              component="h1"
              variant="h5"
              color="primary.dark"
              noWrap
              sx={{mt: 13, fontWeight: 900, flexGrow: 1 }}
            >
              trimmers, inc.
            </Typography>
            <Box sx={{mt:1}}>
            <IconButton
              color="inherit"
              onClick={e => navigate('/')}
            >
              <CloseIcon />
            </IconButton>
            </Box>
          </Toolbar>
          </AppBar>
    <Box sx={{ mx:2}}>
{!slideOut && <BasicInfo step={'quick-create'} fadeIn={true} basicInfo={basicInfo} onChange={updateBasicInfo} onSubmit={() => setSlideOut(!slideOut)}/>}
          <Slide in={slideOut} direction="left">
          <Box sx={{mt:3}}>
            <Box display='flex' flexDirection='row' alignItems='center'>
              <LooksTwoIcon color="success" sx={{mx:2, width:'30px', height:'30px'}}/>
          <Typography sx={{mb:2, mt:2, mr:2, fontWeight: 500}} variant="subtitle1">Now, tap the times when you're available for a quote. </Typography>
          </Box>
          <Typography sx={{mb:3}} variant="subtitle1">If you pick a time before 12p, you can get a quote and tree service done the same day. If not, we'll quote the job and schedule a follow-up for tree service.</Typography>
          {slotMap.map((row, rowIndex) => 
            <Box key={rowIndex+100}>
            <Typography sx={{ml:2, mt:2, fontWeight: 500}} color="success" variant="subtitle1">{row.date}</Typography>
            <Box display="flex" flexDirection="row" justifyContent="center">
              {row.slots.map((slot, slotIndex) => 
                <Box key={slotIndex} sx={{marginRight:'3px'}}>
                <Button sx={{}} key={slotIndex} onClick={() => toggleSlot(rowIndex, slotIndex)} color={slot.open?'success':'inherit'} variant={slot.open?"contained": "contained"}>
                  {slot.time}
                </Button>
                </Box>
              )}
            </Box>
            </Box>
          )}
          </Box>
          </Slide>
       <Box component='div' sx={{width: '100%', borderRadius:8, p:3, my:3, backgroundColor:'white', borderColor:'black', borderWidth:'2px', borderStyle:'dotted'}}>
          <Typography sx={{mt:1}} component="h1" variant="subtitle1">
Everything look good? Click send below to notify our team. We'll respond within 24 hours with your confirmed appointment time.
          </Typography>
          <Typography sx={{my:3}} component="h1" variant="subtitle1">
          </Typography>
            <Button
              onClick={e => handleSendQuote(e)}
              type="submit"
              color='secondary'
              fullWidth
              variant="contained"
            >
              <SendToMobileIcon/><Box sx={{ml:1}}>Send</Box>
            </Button>
          </Box>
    </Box>
    </Box>
  );
}
