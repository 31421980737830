import * as React from 'react';
import defaultTheme from './theme';
import { TransitionProps } from '@mui/material/transitions';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import EmblaCarousel from './EmblaCarousel';
import { EmblaOptionsType } from 'embla-carousel';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate, useLocation } from "react-router-dom";
import { useRef, useEffect, useState } from 'react';
import HowItWorksDialog from './HowItWorksDialog';
import {ITree} from './Trees';
import {IBasicInfo} from './BasicInfo';
import {IExtraCharge} from './ExtraCharges';
import './css/embla.css'

export interface IQuote {
  title?: string;
  createdBy: string;
  basicInfo: IBasicInfo;
  trees: ITree[];
  extras: IExtraCharge[];
  notes?: string;
}

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const defaultBasicInfo = {customerName:'', phoneNumber:'',address:'', email:''};
const defaultExtraCharge = {type: 'select', price: '', description:''};
const defaultQuote = {title:'Quote', createdBy: 'guest', basicInfo: defaultBasicInfo, trees: [], extras: [defaultExtraCharge]};

const OPTIONS: EmblaOptionsType = {}

export default function CreateProviderQuote() {
  const [firstTime, setFirstTime] = useState(true);
  const [HIWDialogOpen, setHIWDialogOpen] = useState(true);
  const [quote, setQuote] = useState<IQuote>(defaultQuote)
  const [trees, setTrees] = useState<ITree[]>([])
  const [extras, setExtras] = useState<IExtraCharge[]>([defaultExtraCharge]);
  const [basicInfo, setBasicInfo] = useState<IBasicInfo>(defaultBasicInfo);
  const [slides, setSlides] = useState([]);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const scrollRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (scrollRef.current && trees.length > 0) {
      //scrollRef.current.scrollIntoView({ behavior: "smooth", block: "end"});
    }
  }, [trees]);


  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
    setExtras(extras.filter(extra => extra.price != ''));
  };

  const handleSaveQuote = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    console.log(trees);
    console.log(basicInfo);
    console.log(extras);

   console.log({created_by: 'john', basic_info: basicInfo, extras: extras, trees: trees});
   return;
    fetch('/api/trim_quotes', {
      method: 'POST',
      //body: JSON.stringify({created_by: location.state.user_id, basic_info: basicInfo, extras: extras, trees: trees}),
      body: JSON.stringify({created_by: 'john', basic_info: basicInfo, extras: extras, trees: trees}),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Do THIS');
        location.state.quotes = [{created_by: location.state.user_id, basic_info: basicInfo, extras: extras, trees: trees}];
        navigate('/home', location);
      })
      .catch((error) => {
        console.log('yo PROBLEMO');
        navigate('/home', {state: {...location.state, quotes:[{created_by: 'john', basic_info: basicInfo, extras: extras, trees: trees}]}});
      });
  };

  const addTree = (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log('adding--PROVIDER');
    event.preventDefault();
    setTrees([...trees, {title: 'Tree #' + (trees.length+1), media: [], width:'', height:''}]);
    console.log(trees);
  };

  const closeHIWDialog = () => {
    if (firstTime) {
      setFirstTime(!firstTime);
    }
    setHIWDialogOpen(!HIWDialogOpen);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
          <HowItWorksDialog open={HIWDialogOpen} onClose={closeHIWDialog} TransitionComponent={Transition} />
          <AppBar color="transparent" sx={{position: 'relative', mb:10}}>
          <Toolbar>
        <Box component="img"
          alt="the tree trimming company"
          src="/ttco_logo.png"
          width="42%"
        />
          <Box sx={{display:'flex', flexGrow: 1, alignItems:'center', justifyContent:'flex-end'}}>
            <IconButton
              color="inherit"
              onClick={e => setHIWDialogOpen(!HIWDialogOpen)}
            >
              <InfoIcon />
            </IconButton>
            <Button autoFocus color="inherit" onClick={handleSaveQuote}>
              Save
            </Button>
            <IconButton
              color="inherit"
              onClick={e => navigate('/signin')}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          </Toolbar>
          </AppBar>
      <Container component="main">
        <Box component="img"
          alt="remote quote"
          src="/rq4_logo.png"
          width="76%"
          sx={{ml:1}}
        />
        <CssBaseline />
        <Box>
          <Container maxWidth="xs" sx={{p:0, mb: 4 }}>
                {!firstTime && <EmblaCarousel slides={[]} quote={quote} options={OPTIONS} onChange={setQuote} />}
                <Box sx={{height:150}} ref={scrollRef}>
                </Box>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
