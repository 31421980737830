import * as React from 'react';
import Fade from '@mui/material/Fade';
import LinearProgress from '@mui/material/LinearProgress';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import Typography from '@mui/material/Typography';
import { useState, useEffect } from 'react';

export interface IBasicInfo {
  phoneNumber?: string;
  customerName?: string;
  address?: string;
  email?: string;
}

interface BasicInfoProps {
  step: string;
  basicInfo: IBasicInfo;
  fadeIn: boolean;
  onChange(basicInfo: IBasicInfo): any;
  onSubmit(): any;
}

export default function BasicInfo(props: BasicInfoProps) {
  const {step, basicInfo, fadeIn, onChange, onSubmit} = props;
  const [fadeOut, setFadeOut] = useState(false);
  const [slideIn, setSlideIn] = useState(false);
  const updateBasicInfo = (basicInfo: {}) => {
    onChange(basicInfo);
  }

  useEffect(() => {
    if (fadeIn) {
      setTimeout(() => setFadeOut(!fadeOut), 2800);
      setTimeout(() => setSlideIn(!slideIn), 3300);
    }
  }, []);

  return (
    <React.Fragment>
      {!slideIn && <Fade in={!fadeOut && fadeIn}>
     <Box>
      <Typography sx={{ mt: 20, ml:5, mb: 4 }} color="inherit" variant="h6" component="div">
        {step=='quick-create'?"OK, let's get you scheduled!":"OK, let's start a new quote!"}
      </Typography>
<LinearProgress color="success" />
</Box>

      </Fade>}
      <Slide in={slideIn} direction="left">
      <Box>
      <Grid sx={{mt: 5, display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems:"center"}}>
        <Grid item xs={2}>
          <LooksOneIcon color="success" sx={{mx:2, width:'30px', height:'30px'}}/>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="subtitle1" component="div" sx={{height:'34px', fontWeight: 500}}>
            {step=='print preview' ? 'Contact Info' : 'First enter your contact info'}
          </Typography>
        </Grid>
      </Grid>
      <Box component="form" noValidate sx={{ mt: 3, mb:3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              disabled={step == 'print preview'}
              required
              fullWidth
              id="phoneNumber"
              label="Phone Number"
              name="phoneNumber"
              onChange={e => updateBasicInfo({...basicInfo, phoneNumber:e.target.value})}
              value={basicInfo.phoneNumber}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              disabled={step == 'print preview'}
              fullWidth
              id="address"
              label="Address"
              name="address"
              autoComplete="address"
              onChange={e => updateBasicInfo({...basicInfo, address:e.target.value})}
              value={basicInfo.address}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={step == 'print preview'}
              fullWidth
              id="name"
              label="Name (optional)"
              name="name"
              autoComplete="given-name"
              onChange={e => updateBasicInfo({...basicInfo, customerName:e.target.value})}
              value={basicInfo.customerName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={step == 'print preview'}
              fullWidth
              name="email"
              label="Email (optional)"
              type="email"
              id="email"
              autoComplete="email"
              onChange={e => updateBasicInfo({...basicInfo, email:e.target.value})}
              value={basicInfo.email}
            />
          </Grid>
        </Grid>
      </Box>
      {step!="print preview" &&
      <Button
        fullWidth
        size="large"
        onClick={onSubmit}
        variant="contained"
        color="primary"
      >
        Next &gt;&gt;
      </Button>}
      </Box>
      </Slide>
    </React.Fragment>
  );
}
