import { createTheme, ThemeOptions, Shadows } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// create a temporary theme to get the default options
const tempTheme = createTheme();
// get the default `shadows` object
const defaultShadows: ThemeOptions['shadows'] = [...tempTheme.shadows];

// A custom theme for this app
const defaultTheme = createTheme({
  shadows: defaultShadows.map(() => 'none') as Shadows,
  palette: {
    mode: 'light',
    primary: {
      main: '#aad2aa',
    },
    secondary: {
      main: '#f50057',
    },
    info: {
      main: '#000',
    },
    background: {
      default: '#ffffff',
      paper: '#ffffff'
    },
  },
  typography: {
    fontFamily: 'Poppins',
  },
  components: {
    MuiButton: {
      defaultProps: {
        sx: {
          borderRadius: 6,
          fontWeight: 500,
          textTransform: "initial !important",
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        color: "secondary",
        sx: {
          fontWeight: 500,
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        color: "secondary",
        sx: {
          borderRadius: 3,
          fontWeight: 500,
          backgroundColor: 'white',
        }
      }
    }
  },
});

export default defaultTheme;
