import { DialogProps } from '@mui/material/Dialog';
import { useNavigate, useLocation } from "react-router-dom";
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ForestIcon from '@mui/icons-material/Forest';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import MediaDialog from './MediaDialog';
import { useState } from 'react';
import { IQuote } from './CreateProviderQuote';
import { TransitionProps } from '@mui/material/transitions';

interface ReviewQuoteDialogProps extends DialogProps {
  quote: IQuote;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ReviewQuoteDialog(props: ReviewQuoteDialogProps) {
  const {quote} = props;
  const [first, setFirst] = useState(true);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [detailImage, setDetailImage] = useState<string>('');
  const navigate = useNavigate();
  const location = useLocation();

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (props.onClose !== undefined) {
      props.onClose(event, "backdropClick")
    }
    setFirst(false);
  };

  const handleViewImage = (media:string) => {
    setDetailImage(media);
    setOpenDialog(true);
  };

  const handleSendQuote = (event: React.MouseEvent<HTMLButtonElement>) => {
        location.state.quote = {created_by: location.state.user_id, basic_info: quote.basicInfo, extras: quote.extras, trees: quote.trees};
        navigate('/status', location);
   return;
/*
    fetch('/api/trim_quotes', {
      method: 'POST',
      body: JSON.stringify({created_by: 'user_id' in location.state ? location.state.user_id : 'guest', basic_info: quote.basicInfo, extras: quote.extras, trees: quote.trees, notes: quote.notes}),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Sent Quote');
        location.state.quote = {created_by: location.state.user_id, basic_info: quote.basicInfo, extras: quote.extras, trees: quote.trees};
        navigate('/status', location);
      })
      .catch((error) => {
        console.log('Unable to send quote');
        //navigate('/home', {state: {...location.state, quotes:[{created_by: 'john', basic_info: basicInfo, extras: extras, trees: trees}]}});
      });
*/
  };

  return (
    <React.Fragment>
      <MediaDialog
        fullScreen
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onSave={() => setOpenDialog(false)}
        TransitionComponent={Transition}
        media={detailImage}
      />
      <Dialog
        fullScreen
        open={props.open}
        onClose={handleClose}
        TransitionComponent={props.TransitionComponent}
      >
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              mt: 1,
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <IconButton color="inherit" onClick={e => handleClose(e)}>
              <CloseIcon/>
            </IconButton>
          </Box>
          <Avatar sx={{ m: 1, bgcolor: 'primary.dark' }}>
            <ForestIcon />
          </Avatar>
            <Typography
              component="h1"
              color="inherit"
              noWrap
              sx={{ mt: 1, fontSize: 20, fontWeight: 900, flexGrow: 1 }}
            >
              Review your Quote
            </Typography>
          <Box>
            <Typography sx={{mb:1}} component="h1" variant="h6">
              Quote ID: 9051-213
            </Typography>
          </Box>
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
    
          <Typography component="h1" variant="h6">
            Your quote includes 
          </Typography>
          <Typography component="h1" variant="h4" sx={{mx:1, fontWeight:900}} color='secondary'>{props.quote.trees.length}
          </Typography>
          <Typography component="h1" variant="h6">
            tree{props.quote.trees.length > 1 && 's'} 
          </Typography>
       </Box>
          {props.quote.trees.map((tree, index) => (
            <Box key={index} sx={{width:'100%', mb:1}}>
            <Typography sx={{mt:3}} component="h1" variant="h6">
              {index+1}. {tree.title}
            </Typography>
            <Typography sx={{mt:0}} component="h1" variant="subtitle1">
              {tree.description ? tree.description :  'No extra details provided'}
            </Typography>
            <Typography color="secondary" display='inline' sx={{mr:1}} component="h1" variant="subtitle1">
              Height: {tree.height ? tree.height: 'Not given'}
            </Typography>|
            <Typography sx={{ml:1}} color="secondary" display='inline' component="h1" variant="subtitle1">
              Width: {tree.width ? tree.width: 'Not given'}
            </Typography>
             {tree.media.length!=0 && <ImageList cols={6} sx={{mb:0}}>
                {tree.media.map((media, mediaIndex) => (
                  <Box key={mediaIndex+1000} sx={{display:'flex', flexGrow:1, flexDirection:'column',alignItems:'center', justifyContent:'center'}}>
                  <ImageListItem key={mediaIndex} onClick={() => handleViewImage(media)}>
                    <Box component='img' sx={{width: '100%', borderRadius:3}} src={media} alt={`image${mediaIndex}`} loading="lazy" />
                  </ImageListItem>
              </Box>
                ))}
              </ImageList>
              }
            </Box>
          ))}
            <Box sx={{width:'100%'}}>
            <Typography sx={{mt:3}} component="h1" variant="h6">
              Additional Notes
            </Typography>
            <Typography sx={{mt:0}} component="h1" variant="subtitle1">
              {quote.notes ? quote.notes :  'No additional notes provided'}
            </Typography>
            </Box>
       <Box component='div' sx={{width: '100%', borderRadius:8, p:3, my:3, backgroundColor:'white', borderColor:'black', borderWidth:'2px', borderStyle:'dotted'}}>
          <Typography sx={{mt:1}} component="h1" variant="subtitle1">
Everything look good? Click send below to deliver this quote to our tree trimming experts. We'll review and respond within 24 hours. 
          </Typography>
          <Typography sx={{my:3}} component="h1" variant="subtitle1">
          </Typography>
            <Button
              onClick={e => handleSendQuote(e)}
              type="submit"
              color='secondary'
              fullWidth
              variant="contained"
            >
              <SendToMobileIcon/><Box sx={{ml:1}}>Send</Box>
            </Button>
          </Box>
          </Box>
      </Container>
      </Dialog>
    </React.Fragment>
  );
}
//send us your available timeslots and we'll text you a confirmed tree trimming appointment.
