import * as React from 'react';
import Box from '@mui/material/Box';

export default function Logo() {
  return (
      <React.Fragment>
        <Box component="img"
          alt="the tree trimming company"
          src="/ttco_logo.png"
          width="70%"
          sx={{mt:1}}
        />
      </React.Fragment>
  );
}
