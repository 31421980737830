import * as React from 'react';
import Tree, {ITree} from './Tree';

export type {ITree};
 
interface TreeProps {
  trees: ITree[];
  variant?: string;
  disabled?: boolean;
  onChange(trees: ITree[]): any;
}

export default function Trees(props: TreeProps) {
  const updateTree = (tree: ITree) => {
    const id = props.trees.findIndex(t => t.title === tree.title);
    props.onChange([...props.trees.slice(0, id), tree, ...props.trees.slice(id + 1)]);
  };

  return (
    <React.Fragment>
      {props.trees.map((tree, index) => 
        <Tree key={index} id={index} onChange={updateTree} variant={props.variant} tree={tree} disabled={props.disabled}></Tree>)}
     </React.Fragment>
  );
}
