import React from 'react'
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Tree, {ITree} from './Tree';
import Notes from './Notes';
import { EmblaOptionsType } from 'embla-carousel'
import { DotButton, useDotButton } from './EmblaCarouselDotButton'
import { IQuote } from './CreateProviderQuote'
import {
  PrevButton,
  NextButton,
  usePrevNextButtons
} from './EmblaCarouselArrowButtons'
import BasicInfo, {IBasicInfo} from './BasicInfo'
import FabBar from './FabBar'
import useEmblaCarousel from 'embla-carousel-react'
import { useState, useEffect, useRef } from 'react';
import ReviewQuoteDialog from './ReviewQuoteDialog'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type PropType = {
  slides: number[]
  quote: IQuote
  options?: EmblaOptionsType
  variant?: string
  disabled?: boolean
  onChange(quote: IQuote): any
}

const EmblaCarousel: React.FC<PropType> = (props) => {
  const { slides, quote, options, variant, disabled, onChange } = props
  const [emblaRef, emblaApi] = useEmblaCarousel(options)
  const [mode, setMode] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const scrollRef = useRef<HTMLDivElement | null>(null);

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi)

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = usePrevNextButtons(emblaApi)

  const updateTree = (tree: ITree, index: number) => {
    if (tree.title === '_empty') {
      onChange({...quote, trees:[...quote.trees.slice(0, index), ...quote.trees.slice(index + 1)]});
    }
    else {
      onChange({...quote, trees:[...quote.trees.slice(0, index), tree, ...quote.trees.slice(index + 1)]});
    }
  };

  const addTree = () => {
    onChange({...quote, trees:[...quote.trees, {title: 'Tree #' + (quote.trees.length+1), media: [], width:'', height:'', description:''}]});
  };

  const gotoNotes = () => {
    if ('notes' in quote === false) {
      console.log('update notes to empty');
      updateNotes('');
    }
    if (selectedIndex != quote.trees.length+1) {
      console.log('move to last!');
      setTimeout(() => onDotButtonClick(quote.trees.length+1), 100);
    }
  };
  const updateNotes = (notes: string) => {
    onChange({...quote, notes:notes});
  };

  const updateBasicInfo = (basicInfo: IBasicInfo) => {
    onChange({...quote, basicInfo:basicInfo});
  };

  useEffect(() => {
    const Id = setTimeout(() => onDotButtonClick(quote.trees.length), 100);
    //if (scrollRef.current) {
    //  scrollRef.current.scrollIntoView({ behavior: "smooth", block: "end"});
    //}
  }, [quote.trees.length]);

  return (
    <section className="embla">
      {selectedIndex!=0 && <FabBar on1={addTree} on2={gotoNotes} on3={e => setOpenDialog(!openDialog)} on4={e => setMode(!mode)} on5={e => setMode(!mode)}/>}
      <Box className="embla__viewport" ref={emblaRef}>
        <Box className="embla__container" ref={scrollRef}>
          <Box className="embla__slide" key={100}>
                <BasicInfo step={'step 1'} fadeIn={true} basicInfo={quote.basicInfo} onChange={updateBasicInfo} onSubmit={onNextButtonClick}/>
          </Box>
      {quote.trees.map((tree, index) => 
          <Box className="embla__slide" key={101 + index}>
              <Tree id={index} onChange={updateTree} variant={variant} tree={tree} disabled={disabled}></Tree>
          </Box>
      )}
      {(quote.notes || quote.notes == '') &&
          <Box className="embla__slide" key={200}>
              <Notes onChange={updateNotes} variant={variant} notes={quote.notes} disabled={disabled}></Notes>
          </Box>
      }
      {quote.trees.length == 0 && (
          <Box className="embla__slide" key={99}>
              <Grid sx={{mt:5, mb:4, display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems:"center"}}>
              <Grid item xs={2}>
              <LooksTwoIcon color="success" sx={{mx:2, width:'30px', height:'30px'}}/>
              </Grid>
              <Grid item xs={10}>
            <Typography variant="subtitle1" component="div" sx={{pr: 2, height:'64px', fontWeight: 500}}>
Now let's capture some photos and videos of the trees
            </Typography>
              </Grid>
              </Grid>
      <Button
        onClick={addTree}
        color="primary"
        fullWidth
        variant="contained"
      >
        Add {quote.trees.length!=0 && 'Another'} Tree
      </Button>
      <Box sx={{mt:2}}/>
            <Button
              disabled={quote.trees.length==0}
              fullWidth
              color="secondary"
              variant={quote.trees.length!=0 ? "contained" : "outlined"}
            >
              {quote.trees.length != 0 ? 'Next Step >>' : 'Finish Quote'}
            </Button>
           <Box sx={{width: '100%', height:600}}/>

            </Box>)}
        </Box>
      </Box>

      <Box className="embla__controls">
        <Box className="embla__buttons">
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </Box>

        <Box className="embla__dots">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={'embla__dot'.concat(
                index === selectedIndex ? ' embla__dot--selected' : ''
              )}
            />
          ))}
      <ReviewQuoteDialog
        fullScreen
        open={openDialog}
        onClose={() => setOpenDialog(!openDialog)}
        TransitionComponent={Transition}
        quote={quote}
      />
        </Box>
      </Box>
      <Paper sx={{ p: 2, mt: 4, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <Button
        onClick={addTree}
        disabled={!quote.trees.length || !quote.trees[0].media.length}
        color="primary"
        fullWidth
        variant="contained"
      >
        Add {quote.trees.length!=0 && 'Another'} Tree
      </Button>
      <Box sx={{mt:2}}/>
            <Button
              onClick={e => setOpenDialog(!openDialog)}
              disabled={!quote.trees.length || !quote.trees[0].media.length}
              fullWidth
              color="secondary"
              variant="contained"
            >
              'Finish Quote'
            </Button>
          </Paper>
    </section>
  )
}

export default EmblaCarousel
