import defaultTheme from './theme';
import * as React from 'react';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate, useLocation } from "react-router-dom";
import { useRef, useEffect, useState } from 'react';
import {Md5} from 'ts-md5';
import FabBar from './FabBar';
import Trees, {ITree} from './Trees';
import HowItWorksDialog from './HowItWorksDialog';
import BasicInfo, {IBasicInfo} from './BasicInfo';
import ExtraCharges, {IExtraCharge} from './ExtraCharges';

interface IQuote {
  title?: string;
  createdBy: string;
  basicInfo: IBasicInfo;
  trees: ITree[];
  extras: IExtraCharge[];
}

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Root = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor:
    theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));

const StyledBox = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const defaultBasicInfo = {customerName:'', phoneNumber:'',address:'', email:''};
const defaultExtraCharge = {type: 'select', price: '', description:''};

export default function CreateUserQuote() {
  const [drawerBleeding, setDrawerBleeding] = useState(0);
  const [HIWDialogOpen, setHIWDialogOpen] = useState(true);
  const [stateMachine, setStateMachine] = useState("step 1");
  const [trees, setTrees] = useState<ITree[]>([]);
  const [extras, setExtras] = useState<IExtraCharge[]>([defaultExtraCharge]);
  const [basicInfo, setBasicInfo] = useState<IBasicInfo>(defaultBasicInfo);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const scrollRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (scrollRef.current && trees.length > 0) {
      scrollRef.current.scrollIntoView({ behavior: "smooth", block: "end"});
    }
  }, [trees]);


  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
    setExtras(extras.filter(extra => extra.price != ''));
  };

  const handleSaveQuote = (event: React.MouseEvent<HTMLButtonElement>) => {
    setStateMachine('step 2');
    event.preventDefault();
    console.log(trees);
    console.log(basicInfo);
    console.log(extras);

   console.log({created_by: 'john', basic_info: basicInfo, extras: extras, trees: trees});
   return;
    fetch('/api/trim_quotes', {
      method: 'POST',
      //body: JSON.stringify({created_by: location.state.user_id, basic_info: basicInfo, extras: extras, trees: trees}),
      body: JSON.stringify({created_by: 'john', basic_info: basicInfo, extras: extras, trees: trees}),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Do THIS');
        location.state.quotes = [{created_by: location.state.user_id, basic_info: basicInfo, extras: extras, trees: trees}];
        navigate('/home', location);
      })
      .catch((error) => {
        console.log('yo PROBLEMO');
        navigate('/home', {state: {...location.state, quotes:[{created_by: 'john', basic_info: basicInfo, extras: extras, trees: trees}]}});
      });
  };

  const goToStep3 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setStateMachine('step 3');
    setDrawerBleeding(56);
    setOpen(true);
  }

  const goToPrintPreview = (event: React.MouseEvent<HTMLButtonElement>) => {
    setStateMachine('print preview');
    event.preventDefault();
    console.log(trees);
  };

  const addTree = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setTrees([...trees, {title: 'Tree #' + (trees.length+1), media: [], width:'', height:''}]);
    console.log(trees);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
          <HowItWorksDialog open={HIWDialogOpen} onClose={() => setHIWDialogOpen(!HIWDialogOpen)} /> 
          <AppBar sx={{justifyContent:'center', position: 'relative'}}>
          <Toolbar>
            <Typography variant="h5" sx={{ml:1, fontWeight: 900, flex:1}} component="div">
              {stateMachine != 'print preview' ? 'New' : 'Review'} Quote
            </Typography>
            <IconButton
              color="inherit"
              onClick={e => setHIWDialogOpen(!HIWDialogOpen)}
              aria-label="close"
            >
              <InfoIcon />
            </IconButton>
            <Button autoFocus color="inherit" onClick={handleSaveQuote}>
              Save
            </Button>
            <IconButton
              color="inherit"
              onClick={e => stateMachine == 'print preview' ? setStateMachine('step 3'):navigate('/home')}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          </AppBar>
      <Container component="main"
>
        <CssBaseline />
        <Box sx={{background: 'linear-gradient(left top, left bottom, from(rgba(257, 255, 255, 1)), to(rgba(0, 0, 0, 0)))'}}>
          <FabBar on1={addTree} on2={toggleDrawer(!open)} on3={goToPrintPreview} on4={handleSaveQuote} on5={toggleDrawer(!open)}/>
          <Container maxWidth="xs" sx={{p:0, mb: 4 }}>
            <Grid container>
              {/* Recent Jobs */}
              <Grid item xs={12}>
                <BasicInfo step={stateMachine} fadeIn={true} basicInfo={basicInfo} onChange={(info)=>setBasicInfo(info)} onSubmit={()=>setStateMachine('step 2')}/>

              {stateMachine!="step 1"  && <Grid sx={{display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems:"center"}}>
              <Grid item xs={2}>
              <LooksTwoIcon color="success" sx={{mx:2, width:'30px', height:'30px'}}/>
              </Grid>
              <Grid item xs={10}>
            <Typography variant="subtitle1" component="div" sx={{height:'34px'}}>
{stateMachine=='print preview' ? 'Trees Included in Quote' : 'Now capture some photos and videos of the trees'}
            </Typography>
              </Grid>
              </Grid>}
        {trees.length!=0 && 
        <Trees onChange={setTrees} variant="user" trees={trees} disabled={stateMachine == 'print preview'}></Trees>}
          {stateMachine == 'print preview' && extras.length != 0 && 
          <Box sx={{mt:3, pb:50}}>
            <Grid sx={{display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems:"center"}}>
              <Grid item xs={2}>
              <Looks3Icon color="success" sx={{mx:2, width:'30px', height:'30px'}}/>
              </Grid>
              <Grid item xs={10}>
            <Typography variant="subtitle1" component="div" sx={{height:'34px'}}>
              Extra Charges
            </Typography>
              </Grid>
              </Grid>
              {extras.length != 0 && <ExtraCharges disabled={true} onChange={setExtras} extraCharges={extras}></ExtraCharges>}
          </Box>
          }
        </Grid>
      </Grid>
      {stateMachine != 'print preview' && <Paper sx={{ p: 2, mt: 4, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <Button
        onClick={addTree}
        disabled={stateMachine=="step 1"}
        color="success"
        fullWidth
        type="submit"
        variant="outlined"
        sx={{ mx: 2, mb:2 }}
      >
        Add {stateMachine!='step 1'&& trees.length!=0 && 'Another'} Tree
      </Button>
            <Button

              onClick={stateMachine=='step 2'? goToStep3 : goToPrintPreview}
              disabled={trees.length==0}
              fullWidth
              color="secondary"
              type="submit"
              variant={stateMachine!='step 1'?"contained":"outlined"}
              sx={{ mx: 2 }}
            >
              {stateMachine=='step 2'?'Next Step >>':'Finish Quote'}
            </Button>
            </Paper>}
                <Box sx={{height:150}} ref={scrollRef}>
                </Box>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Container>
<Root>
     <CssBaseline />
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: `calc(50% - ${drawerBleeding}px)`,
            overflow: 'visible',
          },
        }}
      />
<Drawer
        container={document.body}
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            position: 'absolute',
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: 'visible',
            right: 0,
            left: 0,
          }}
        >
        <AppBar color="success" sx={{ position: 'relative' }}>
          <Toolbar>
            <Looks3Icon sx={{ml:2, width:'30px', height:'30px'}}/>
            <Typography sx={{ ml: 1 }} variant="subtitle1" component="div">
              Add Extra Charges
            </Typography>
            <Button fullWidth sx={{flex: 1, justifyContent: 'flex-end' }} autoFocus color="inherit" onClick={toggleDrawer(!open)}>
              {!open ? 'View' : 'Save'}
            </Button>
          </Toolbar>
        </AppBar>
        </StyledBox>
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: '100%',
            overflow: 'auto',
          }}
        >
          <Box component="form" noValidate>
            <ExtraCharges disabled={false} onChange={setExtras} extraCharges={extras}></ExtraCharges>
          </Box>
        </StyledBox>
      </Drawer>
      </Root>
    </ThemeProvider>
  );
}
