import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import * as React from 'react';
import TextField from '@mui/material/TextField';
import {useEffect, useState} from 'react';

export interface IExtraCharge {
  type?: string;
  price: string;
  description?: string;
}

interface ExtraChargeProps {
  extraCharges: IExtraCharge[];
  variant?: string;
  disabled?: boolean;
  onChange(extraCharges: IExtraCharge[]): any;
}

const defaultExtraCharge = {type: 'select', price: '', description:''};

export default function ExtraCharges(props: ExtraChargeProps) {
  const [allowAddExtra, setAllowAddExtra] = useState(true);

  const addExtraCharge = () => {
    props.onChange([...props.extraCharges, defaultExtraCharge]);
    setAllowAddExtra(false);
  };

  const updateExtraCharge = (extraCharge: IExtraCharge, id: number) => {
    if (extraCharge.price == 'DELETE') {
      console.log('deleting');
      if (props.extraCharges.length == 1) {
        props.onChange([defaultExtraCharge]);
        return;
      }
      props.onChange([...props.extraCharges.slice(0, id), ...props.extraCharges.slice(id + 1)]);
      return;
    }
    props.onChange([...props.extraCharges.slice(0, id), extraCharge, ...props.extraCharges.slice(id + 1)]);
    setAllowAddExtra(extraCharge.price.length != 0)
  };

  return (
    <React.Fragment>
      {props.extraCharges.map((extra, index) =>
            <Grid key={index} container sx={{my:2}} spacing={1}>
              <Grid item xs={4}>
               <FormControl fullWidth>
                 <InputLabel id="demo-simple-select-label">Type</InputLabel>
                 <Select
                   disabled={props.disabled}
                   sx={{ minWidth: 120 }}
                   labelId="demo-simple-select-label"
                   id="demo-simple-select"
                   label="Type"
                   onChange={e => updateExtraCharge({...extra, type:e.target.value}, index)}
                   value={extra.type}
                 >
                   <MenuItem value={'select'}>Select</MenuItem>
                   <MenuItem value={'gas'}>Gas</MenuItem>
                   <MenuItem value={'haul off'}>Haul Off</MenuItem>
                   <MenuItem value={'other'}>Other</MenuItem>
                 </Select>
               </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled={props.disabled}
                  required
                  fullWidth
                  id="price"
                  label="Price"
                  name="price"
                  onChange={e => updateExtraCharge({...extra, price:e.target.value}, index)}
                  value={extra.price}
                />
              </Grid>
              <Grid item xs={4}>
                <Button
                  disabled={extra.price==''}
                  variant="outlined"
                  fullWidth
                  color="secondary"
                  sx={{ height: '100%' }}
                  onClick={e => updateExtraCharge({price:"DELETE"}, index)}
                >
                  <DeleteIcon />DELETE
                </Button>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={props.disabled}
                  fullWidth
                  multiline
                  minRows={1}
                  maxRows={2}
                  id="description"
                  label="Description"
                  name="description"
                  autoComplete="given-name"
                  placeholder="Describe how you calculated the price"
                  onChange={e => updateExtraCharge({...extra, description:e.target.value}, index)}
                  value={extra.description}
                />
              </Grid>
            </Grid>
      )}
      <Button
        type="submit"
        disabled={!allowAddExtra}
        onClick={addExtraCharge}
        fullWidth
        variant="contained"
      >
        Add Another Extra
      </Button>
    </React.Fragment>
  );
}
