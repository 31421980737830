import AppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import LoopIcon from '@mui/icons-material/Loop';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Webcam from "react-webcam";
import * as React from 'react';
import { DialogProps } from '@mui/material/Dialog';
import {useRef, useState, useCallback, useEffect} from 'react';

import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

interface MediaDialogProps extends DialogProps {
  media: string;
  onSave: (media: string) => any;
}

const allSteps = [[
  {
    description: "It's best to capture at least 2 shots of each tree, one to identify it and one to show what you want done. Use your voice in videos!",
    label: "How To"
  },
  {
    description: "The first shot we'll take is called a Profile shot: it's the shot we'll use to identify this tree. It can be a photo or video.",
    label: "Shot 1: Profile Shot"
  },
  {
    description: "Stand far enough away so that the the highest branch is at the top of the frame and the base of the trunk at the bottom.",
    label: "Shot 1: Profile Shot"
  },
  {
    description: "For trees that are wide, rotate your phone horizontal to use landscape mode, otherwise keep your phone vertical.",
    label: "Shot 1: Profile Shot"
  },
  {
    description: "If you can safely do so, walk around the tree while keeping most of it in the shot to capture a 360 degree profile video.",
    label: "BONUS POINTS"
  }],
  [{
    description: "The second shot will be a Trunk shot: a single shot were we can see all of the major limbs.",
    label: "Shot 2: Trunk Shot"
  },
  {
    description: "Stand about 1-2 ft away from where the trunk meets the ground and look up to the top of the tree. Start filming while you slowly take a few steps back from the tree. Choose a spot where you can do this safely and get a view of most of the main branches",
    label: "Shot 2: Trunk Shot"
  }],
  [{
    description: "Now we'll take additional shots for larger trees. If the tree has long limbs that were not easily visibly in the first two shots, take a Limb shot for each one.", 
    label: "Additional Shots: Limb Shots"
  },
  {
    description: "Starting from the bottom most limb, stand about 6ft away from where the limb start and start filming while you slowly pan to the end of limb. If you can safely do so, step towards the end of the limb while you film. Repeat this process as you work your way up the tree for each long limb. ",
    label: "Additional Shots: Limb Shots"
  },
  {
    description: "Lastly, if you didn't get a good view of all sides of the tree (and didn't get a 360 degree video), you may want to take a back or side profile shot, similar to our front profile shot ",
    label: "Additional Shots: Back or Side Profile"
  }]
];


export default function MediaDialog(props: MediaDialogProps) {
  const webcamRef = useRef<Webcam>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [capturing, setCapturing] = useState(false);
  const [facingMode, setFacingMode] = useState("user");
  const [videoTime, setVideoTime] = useState(0);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [retakeMode, setRetakeMode] = useState(false);
  const [guideStepId, setGuideStepId] = useState(0);
  const [detailImage, setDetailImage] = useState<string>(props.media);
  const [activeShot, setActiveShot] = React.useState(0);
  const [activeStep, setActiveStep] = React.useState(0);
  const [open, setOpen] = React.useState(true);
  const [photoNotVideo, setPhotoNotVideo] = React.useState(true);
  const guideSteps = allSteps[0];
  const maxSteps = guideSteps.length;

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % guideSteps.length);
    if (activeStep == guideSteps.length-1) {
      setOpen(!open);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const capturePhoto = () => {
    if ('current' in webcamRef && webcamRef.current != null) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        setRetakeMode(true);
        setDetailImage(imageSrc);
      }
    }
  };
  
  const savePhoto = () => {
    if (detailImage && props.onSave !== undefined) {
      props.onSave(detailImage);
    }
    setRetakeMode(false);
  };

  const capture = () => {
    if (photoNotVideo) {
      return capturePhoto();
    }
    if (!capturing) {
      return captureVideo();
    }
    return stopCaptureVideo();
  };

  const captureVideo = useCallback(() => {
     console.log('started video capture');
    setCapturing(true);
    if ('current' in webcamRef && webcamRef.current != null) {
      if ('stream' in webcamRef.current && webcamRef.current.stream != null) {
     console.log('video capture - found stream');
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {mimeType: "video/mp4"});
      mediaRecorderRef.current.addEventListener(
        "dataavailable",
        handleDataAvailable
      );
      mediaRecorderRef.current.start();
      setVideoTime(0);
    }
    }
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = React.useCallback(
    ({data}: any) => {
      console.log('data available - size: ' + data.size);
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const stopCaptureVideo = useCallback(() => {
    console.log('stopped video capture');
    mediaRecorderRef.current?.stop();
    setCapturing(false);
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  const handleDownload = useCallback(() => {
    if (recordedChunks.length) {
      console.log('video capture - found chunks');
      const blob = new Blob(recordedChunks, {
        type: "video/mp4"
      });
      setRecordedChunks([]);
      setRetakeMode(true);
      setDetailImage(URL.createObjectURL(blob));
    }
  }, [recordedChunks]);

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (props.onClose !== undefined) {
      props.onClose(event, "backdropClick")
    }
    setRetakeMode(false);
  };

  useEffect(() => {
    if (recordedChunks.length > 0 && !capturing) {
      handleDownload();
    }
    if (capturing) {
      setTimeout(() => {
        setVideoTime(videoTime + 100);
      }, 100);
    }
  }, [capturing, recordedChunks, videoTime]);

 const action = (
    <React.Fragment>
      <Button color="secondary" size="small" sx={{mr:19}} onClick={e => setGuideStepId(0)}>
        START OVER
      </Button>
      <Button color="secondary" size="small" onClick={e => setGuideStepId(0)}>
        NEXT
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={e => setGuideStepId(guideStepId +1)}
      >
        <NavigateNextIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={props.open}
        onClose={props.onClose}
        TransitionComponent={props.TransitionComponent}
      >
        <AppBar sx={{position: 'relative' }}>
          <Toolbar sx={{backgroundColor: 'black', display: 'flex', justifyContent: 'flex-end'}}>
          <Box sx={{display: 'flex', flexGrow: 1, justifyContent: 'start'}}>
            <Button
              fullWidth
              variant={!photoNotVideo ? "outlined" : "contained"}
              onClick={e => setPhotoNotVideo(!photoNotVideo)}
              sx={{mx:'2px'}}
            >
              PHOTO
            </Button>
            <Button
              fullWidth
              variant={photoNotVideo ? "outlined" : "contained"}
              onClick={e => setPhotoNotVideo(!photoNotVideo)}
              sx={{mx:'2px'}}
            >
              VIDEO
            </Button>
         </Box>
            <Button
              onClick={e => handleClose(e)}
            >
              <CloseIcon />
            </Button>
          </Toolbar>
        </AppBar>
        <Box sx={{bgcolor:'#000', maxWidth:'100%', height:'100%'}}>
{props.media=='' && !retakeMode && 
          <Webcam
            audio={false}
            ref={webcamRef}
            videoConstraints={{facingMode: facingMode}}
            screenshotFormat="image/jpeg"
            style={{width: "100vw"}}
          />
} {props.media=='' && retakeMode && 
          <Box
            component={photoNotVideo? "img":"video"}
            src={detailImage}
            controls
          />
}{props.media !== '' &&
          <Box
            component="img"
            alt="Detailed Image"
            src={props.media}
          />
}
        </Box>
    <Box sx={{opacity: .5, bgcolor: '#000', position: 'fixed', bottom: 0, width: '100%', display:'flex', flexDirection: 'row', justifyContent:"center", alignItems:"start", height: 120}}/>
    <Box sx={{bgcolor: 'transparent', position: 'fixed', bottom: 0, width: '100%', display:'flex', flexDirection: 'row', justifyContent:"center", alignItems:"start", height: 120}}>
  {props.media=='' && retakeMode && 
        <Box sx={{spacing: 2, width: "90%"}}>
          <Button
            onClick={savePhoto}
            variant="contained"
            fullWidth
          >
            Keep
          </Button>
          <Box sx={{mt:2}}/>
          <Button
            onClick={() => {setRetakeMode(false);setVideoTime(0)}}
            variant="contained"
            fullWidth
          >
            Retake
          </Button>
        </Box>
}
{!retakeMode &&
          <Box sx={{display:"flex", width:'40%', alignItems:"center", justifyContent:"center", height:120}}>
{!photoNotVideo &&
            <Button variant="outlined">
              {("0" + Math.floor((videoTime / 60000) % 60)).slice(-2)}:
              {("0" + Math.floor((videoTime / 1000) % 60)).slice(-2)}
            </Button>
}
            <Button onClick={e => setFacingMode(facingMode==="user" ? "environment" : "user")}>
              <LoopIcon sx={{height:30, width:30}}/>
            </Button>
          </Box>
}
{props.media=='' && !retakeMode && 
          <Box display="flex" width='20%' justifyContent="center">
            <IconButton color={!capturing ? 'primary' : 'secondary'} onClick={capture}>
              <RadioButtonCheckedIcon sx={{height:100, width:100}}/>
            </IconButton>
          </Box>
}
{!retakeMode &&
          <Box sx={{display:"flex", width:'40%', alignItems:"center", justifyContent:"center", height:120}}>
            <IconButton color='primary' onClick={toggleDrawer(!open)}>
              <InfoOutlinedIcon sx={{height:30, width:30}}/>
            </IconButton>
          </Box>
}
   </Box> 
   <Slide in={open} direction="up">
    <Box sx={{bgcolor: '#eee', position: 'fixed', bottom: 0, width: '100%', display:'flex', flexDirection: 'column', justifyContent:"flex-end", flexGrow: 1 }}>
      <Box sx={{display:'flex', flexDirection: 'row', justifyContent:"space-between", alignItems:"center"}}>
      <Typography variant='h6'
        color="secondary"
        sx={{
          mt: 1,
          pl: 2,
        }}
      >
        {guideSteps[activeStep].label}</Typography>
      <IconButton
        sx={{
          mt: 1,
          pr: 2,
        }}
        size="small"
        aria-label="close"
        color="secondary"
        onClick={toggleDrawer(!open)}
      >
        <ExpandMoreIcon fontSize="large" />
      </IconButton>
      </Box>
      <Box sx={{ height: 180, maxWidth: 400, width: '100%', p: 2 }}>
        {guideSteps[activeStep].description}
    <Box sx={{display:'flex', height:'50%', alignItems:"center", justifyContent:"center", flexGrow: 1 }}>
          <Button
            fullWidth
            variant="contained"
            size="small"
            onClick={toggleDrawer(!open)}
            color="secondary"
          >
            Just Start Shooting
          </Button>
          </Box>
      </Box>
      <MobileStepper
        variant="text"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            color="success"
          >
            {activeStep != guideSteps.length-1 ? 'Next' : 'Done'}
            {activeStep != guideSteps.length-1 && <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0} color="success">
              <KeyboardArrowLeft />
            Back
          </Button>
        }
      />
    </Box>
    </Slide>
{false && <Snackbar
  open={true}
  autoHideDuration={9000}
  onClose={e => setGuideStepId(guideStepId +1)}
  message={guideSteps[guideStepId].description}
  action={action}
/>}
      </Dialog>
    </React.Fragment>
  );
}
