import defaultTheme from './theme';
import Logo from './Logo';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { useState, useRef } from "react";
import {Md5} from 'ts-md5';
import JoinTheClub from './JoinTheClub';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SignIn() {
  const navigate = useNavigate();
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [joinClubDialogOpen, setJoinClubDialogOpen] = useState(false);
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget) as any;
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });

    data.set("password", Md5.hashStr(data.get('password')));
    
    fetch('/api/trim_users?' + new URLSearchParams(data).toString(), {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data
        console.log('Do THIS');
        if (data.Count == 0) {
	  console.log('no op');
	} else {
	  console.log(data.Items[0]);
	  navigate('/home', {state: data.Items[0]});
	}
      })
      .catch((error) => {
        // Handle any errors
	navigate('/signin');
      });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <JoinTheClub open={joinClubDialogOpen} onClose={() => setJoinClubDialogOpen(!joinClubDialogOpen)} TransitionComponent={Transition} />
      <Container component="main" maxWidth="sm"
          sx={{
            backgroundPositionX: '25%',  
            backgroundPositionY: '23%',  
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url("https://cdn.hovia.com/app/uploads/green-watercolour-forest-tree-silhouette-wallpaper-mural-Plain.jpg")`,
            backgroundSize: '210%'
         }}
        >
        <CssBaseline />
        <Box
          sx={{
            pt: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
         }}
        >
          <Logo/>
          <Typography sx={{mt:2, mx:4, fontWeight: 600, textAlign:'center'}} component="h1" variant="subtitle1">
            Use your smart phone to get your trees trimmed at a great price.
          </Typography>
       <Slide in direction="up" timeout={800}><Box component='div' sx={{width: '100%', borderRadius:8, ml:0, p: 2, pb: 3, mt:3, backgroundColor:'white', backgroundImage: `url("")`, borderColor:'warning.main', borderWidth:'1px', borderStyle:'solid'}}>
       <Box sx={{display: 'flex', justifyContent: 'start'}}>
          <Typography color="warning.main" sx={{ml:1}} variant="subtitle2">
          Lowest Cost Option
          </Typography>
       </Box>
        <Box component="img"
          alt="remote quote"
          src="/rq4_logo.png"
          width="76%"
          sx={{ml:1}}
        />
          <Typography sx={{mb:2, mx:1}} component="h1" variant="subtitle1">
          Save money by creating your own video-powered tree trimming quote!
          </Typography>
            <Button
              onClick={() => navigate('/provider/quote', {state: {user_id: "guest"}})}
              type="submit"
              color='warning'
              fullWidth
              variant="contained"
            >
              {'Create your own quote now'}
            </Button>
        </Box></Slide>
       <Slide in direction="up" timeout={800}><Box component='div' sx={{width: '100%', borderRadius:8, mr:0, p:2, pb:3, mt:2, mb:4, backgroundColor:'white', backgroundImage: `url("")`, borderColor:'success.main', borderWidth:'1px', borderStyle:'solid'}}>
       <Box sx={{display: 'flex', justifyContent: 'start'}}>
          <Typography color="success.main" sx={{ml:1}} variant="subtitle2">
          Fastest Option
          </Typography>
       </Box>
        <Box component="img"
          alt="zip scheduler"
          src="/zs_logo.png"
          width="70%"
          sx={{ml:1}}
           
        />
          <Typography sx={{mb:2, ml:1}} component="h1" variant="subtitle1">
          Save time and get a certified tree expert to your house with 1-click!
          </Typography>
            <Button
              onClick={() => navigate('/quick-create', {state: {user_id: "guest"}})}
              type="submit"
              color='success'
              fullWidth
              variant="contained"
            >
              {'Schedule an estimate in 1 click'}
            </Button>
        </Box></Slide>
       <Slide in direction="up" timeout={800}>
          <Button color="secondary" variant="contained" onClick={()=>setJoinClubDialogOpen(!joinClubDialogOpen)}>
          SAVE <Typography sx={{mx:'5px',fontWeight:700}}>BIG</Typography> BY JOINING OUR CLUB
          </Button>
        </Slide>
       {false && <Box display='flex' flexDirection='row'>
       <Box component='div' sx={{width: '50%', borderRadius:8, p:3, mr:1, backgroundColor:'white', borderColor:'secondary.main', borderWidth:'1px', borderStyle:'solid'}}>
          <Button color="secondary" variant="outlined">
          Low Maintenance
          </Button>
          <Typography sx={{my:2, ml:1, textAlign:'center'}} component="h1" variant="subtitle1">
          Subscriptions starting at $39/month
          </Typography>
            <Button
              onClick={() => navigate('/provider/quote', {state: {user_id: "guest"}})}
              type="submit"
              color='secondary'
              fullWidth
              variant="contained"
            >
              {'Get Started'}
            </Button>
        </Box>
       <Box component='div' sx={{width: '50%', borderRadius:8, p:3, ml:1, backgroundColor:'white', borderColor:'info.main', borderWidth:'1px', borderStyle:'solid'}}>
          <Button color="info" variant="outlined">
          Flex Savings
          </Button>
          <Typography sx={{my:2, ml:1, textAlign:'center'}} component="h1" variant="subtitle1">
          Book now, book again and save
          </Typography>
            <Button
              onClick={() => navigate('/provider/quote', {state: {user_id: "guest"}})}
              type="submit"
              color='info'
              fullWidth
              variant="contained"
            >
              {'Get Started'}
            </Button>
        </Box>
        </Box>}
            <Box sx={{mt:1, mb:5}}>
                <Link href="#" color="info.main" variant="subtitle1" sx={{fontWeight:600, mt:2}}>
                  {'Learn how it works'}
                </Link>
             </Box>
          <Button size="large" color='secondary' variant="outlined" onClick={() => scrollRef.current?.scrollIntoView({ behavior: "smooth", block: "end"})}>
            <LockOutlinedIcon /><Box sx={{ml:1}}>Or, Sign In</Box>
          </Button>
          <Typography sx={{mt:1}} component="h1" variant="subtitle1">
           If you are a professional trimmer
          </Typography>
          <Typography component="h1" variant="subtitle1">
           or already have an account
          </Typography>
          <Typography sx={{mb:1, fontWeight: 600}} component="h1">
          please sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
              sx={{mb:2}}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
            >
              Sign In
            </Button>
            <Grid container sx={{mt:2}}>
              <Grid item xs>
                <Link href="#" color="primary.dark" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/signup" color="primary.dark" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }}/>
      </Container>
      <Box ref={scrollRef}/>
    </ThemeProvider>
  );
}
