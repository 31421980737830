import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';

// Generate Jobs Data
function createData(
  id: number,
  status: string,
  date: string,
  name: string,
  address: string,
) {
  return { id, status, date, name, address };
}

const rows = [
  createData(
    0,
    'Ready to Schedule',
    '16 Mar, 2024',
    'Elvis Presley',
    '4801 Springdale Dr.',
  ),
  createData(
    1,
    'Payment Received',
    '16 Mar, 2024',
    'Paul McCartney',
    '8981 Garfeild Ave.',
  ),
  createData(2, 'Payment Received', '16 Mar, 2024', 'Tom Scholz', '11211 Barton Springs Dr.'),
  createData(
    3,
    'Quote Sent',
    '16 Mar, 2024',
    'Michael Jackson',
    '10002 Blue Arrow Dr.',
  ),
  createData(
    4,
    'Invoice Sent',
    '15 Mar, 2024',
    'Bruce Springsteen',
    '45 Hardford Ln. Apt. 34',
  ),
];

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

export default function Jobs() {
  return (
    <React.Fragment>
      <Title>Recent Jobs</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Address</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.status}</TableCell>
              <TableCell>{row.date}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.address}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
        See more orders
      </Link>
    </React.Fragment>
  );
}
