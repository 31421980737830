import * as React from 'react';
import ReactCardFlip from 'react-card-flip';
import Grid from '@mui/material/Grid';
import Badge, { BadgeProps } from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Title from './Title';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { TransitionProps } from '@mui/material/transitions';
import {useRef, useState} from 'react';
import MediaDialog from './MediaDialog';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export interface ITree {
  title: string;
  price?: string;
  description?: string;
  media: string[];
  width?: string;
  height?: string;
}

interface TreeProps {
  tree: ITree;
  id: number;
  variant?: string;
  disabled?: boolean;
  onChange(tree: ITree, index: number): any;
}

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 12,
    top: 12,
    padding: '0 4px',
  },
}));

const treeColors = ['primary.light', 'lightblue', 'lightgrey', 'lightyellow', 'lightpink'];
const treeURLs = [
  "https://oppositewall.com/cdn/shop/products/TROLL.jpg?v=1644954235&width=990",
  "https://oppositewall.com/cdn/shop/products/WHIMSY.jpg?v=1644954235&width=990",
  "https://oppositewall.com/cdn/shop/products/PIXIE.jpg?v=1644954235&width=990",
  "https://oppositewall.com/cdn/shop/products/FAIRY.jpg?v=1644954235&width=990",
  "https://c7.alamy.com/comp/2EANP2W/summer-or-spring-foliage-green-tree-leaves-seamless-pattern-background-for-wallpaper-tiles-and-fabric-design-2EANP2W.jpg", 
  "https://oppositewall.com/cdn/shop/products/GREEN_4859312b-7841-43fc-a643-3de63ea47d4c.jpg?v=1657144429&width=990",
];

export default function Tree(props: TreeProps) {
  const {tree, id, variant, disabled, onChange} = props;
  const [openMediaDialog, setOpenMediaDialog] = React.useState(false);
  const [openRemoveDialog, setOpenRemoveDialog] = React.useState(false);
  const [detailImage, setDetailImage] = useState<string>('');
  const [editMode, setEditMode] = useState(false);
  const [editPhotoMode, setEditPhotoMode] = useState(false);

  const updateTree = (tree: ITree) => {
    onChange(tree, id);
  };

  const deleteMedia = (index: number) => {
      updateTree({...tree, media:[...tree.media.slice(0, index), ...tree.media.slice(index + 1)]});
  };

  const deleteTree = () => {
      setOpenRemoveDialog(false);
      updateTree({title:'_empty', media:[]});
  };

  const savePhoto = (media: string) => {
    if (media) {
      updateTree({...tree, media: [...tree.media, media]});
      setOpenMediaDialog(false);
    }
  };

  const handleViewMedia = (index: number) => {
    setDetailImage(tree.media[index]);
    setOpenMediaDialog(true);
  };

  const handleAttachMedia = () => {
    setDetailImage('');
    setOpenMediaDialog(true);
  };

  return (
    <React.Fragment>
      <Dialog
        open={openRemoveDialog}
        onClose={() => setOpenRemoveDialog(false)}
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to remove this tree?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action will permanently remove this tree and all photos and video attached to it. This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={e => setOpenRemoveDialog(false)}>Disagree</Button>
          <Button onClick={deleteTree} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
       <ReactCardFlip isFlipped={editMode}>
       <Box component='div' sx={{width: '100%', borderRadius:8, p:3, pt:6, my:2, backgroundImage:(tree.media.length!=0) ? `url(${tree.media[0]})` : `url(${treeURLs[id]})`, backgroundColor:treeColors[id]}}>
      <MediaDialog
        fullScreen
        open={openMediaDialog}
        onClose={e => setOpenMediaDialog(false)}
        onSave={savePhoto}
        TransitionComponent={Transition}
        media={detailImage}
      />
          <Grid container>
            <Grid item sx={{display:'flex', flexDirection: "row", flexGrow:1, justifyContent: "flex-end"}}>
              <Grid sx={{mr:1}} item>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={e => setOpenRemoveDialog(!openRemoveDialog)}
                >
                  Remove
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="warning"
                  variant="contained"
                  onClick={e => setEditMode(!editMode)}
                >
                  Edit
                </Button>
              </Grid>
              </Grid>
            <Grid item sx={{width: '100%', display:'flex', flexDirection: "row", flexGrow:1, justifyContent: "space-between"}}>
              <Grid item>
                <Typography component="h2" variant="h4" color={tree.media.length ? "white" : "black"} sx={{pt: 14, fontWeight:700}} gutterBottom>
                  {tree.title}
                </Typography>
              </Grid>
              {variant!='user' && <Grid xs={3} item>
                <Title>{tree.price}</Title>
              </Grid>
              }
            </Grid>
              <Grid xs={12} item>
            <Typography sx={{mb:2}} variant="subtitle1" component="div" color={tree.media.length ? "white" : "black"} >
              {tree.description==='' ? 'Click the edit button to add a description and details' : tree.description}
            </Typography>
              </Grid>
            {!disabled && <Grid xs={12} sx={{mb:2}} item>
              <Button
                fullWidth
                color="info"
                variant="contained"
                onClick={e => handleAttachMedia()}
              >
                Add {tree.media.length > 0 && 'More'} Photos and Videos
              </Button>
            </Grid>}
          </Grid>
       </Box>
       <Box component='div' sx={{width: '100%', borderRadius:8, p:3, pt: '31px', my:3, borderColor: 'black', borderStyle: 'dotted', borderWidth: '2px', backgroundColor:'white'}}>
            <Grid container spacing={2} sx={{mb:1}}>
              <Grid item sx={{mb: 2, width: '100%', display:'flex', flexDirection: "row", flexGrow:1, justifyContent: "space-between"}}>
              <Grid xs={6} item>
                <Typography sx={{ml:1, color:'black'}} variant="h6">
                  Add Details
                </Typography>
              </Grid>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={e => setEditMode(!editMode)}
                >
                  Close
                </Button>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={disabled}
                  required
                  fullWidth
                  id="title"
                  label="Tree Name or Location"
                  name="title"
                  onChange={e => updateTree({...tree, title:e.target.value})}
                  value={tree.title}/>
              </Grid>
              {variant=='provider' && <Grid item xs={12}>
                <TextField
                  disabled={disabled}
                  required
                  fullWidth
                  id="price"
                  label="Price"
                  name="price"
                  onChange={e => updateTree({...tree, price:e.target.value})}
                  value={tree.price}/>
              </Grid>}
              <Grid item xs={12}>
                <TextField
                  disabled={disabled}
                  fullWidth
                  multiline
                  minRows={2}
                  maxRows={4}
                  id="description"
                  label="Description"
                  name="description"
                  autoComplete="given-name"
                  placeholder="Provide any useful details about the tree"
                  onChange={e => updateTree({...tree, description:e.target.value})}
                  value={tree.description}/>
              </Grid>
              <Grid item xs={5}>
               <FormControl fullWidth>
                 <InputLabel>Height (opt)</InputLabel>
                 <Select
                   disabled={disabled}
                   sx={{ minWidth: 120 }}
                   label="Height (opt)"
                   onChange={e => updateTree({...tree, height:e.target.value})}
                   value={tree.height}
                 >
                   <MenuItem value={''}>{'Select'}</MenuItem>
                   <MenuItem value={'< 10 ft'}>{'< 10 ft'}</MenuItem>
                   <MenuItem value={'10 - 20 ft'}>10 - 20 ft</MenuItem>
                   <MenuItem value={'20 - 30 ft'}>20 - 30 ft</MenuItem>
                   <MenuItem value={'> 30 ft'}>{'> 30 ft'}</MenuItem>
                 </Select>
               </FormControl>
              </Grid>
              <Grid item xs={7}>
               <FormControl fullWidth>
                 <InputLabel>Trunk Width (opt)</InputLabel>
                 <Select
                   disabled={disabled}
                   sx={{ minWidth: 120 }}
                   label="Trunk Width (opt)"
                   onChange={e => updateTree({...tree, width:e.target.value})}
                   value={tree.width}
                 >
                   <MenuItem value={''}>{'Select'}</MenuItem>
                   <MenuItem value={'< 1 ft Across'}>{'< 1 ft Across'}</MenuItem>
                   <MenuItem value={'1 - 2 ft Across'}>1 - 2 ft Across</MenuItem>
                   <MenuItem value={'2 - 3 ft Across'}>2 - 3 ft Across</MenuItem>
                   <MenuItem value={'> 3 ft Across'}>{'> 3 ft Across'}</MenuItem>
                 </Select>
               </FormControl>
              </Grid>
              <Grid xs={12} item>
                <Button
                  color="info"
                  variant="contained"
                  fullWidth
                  onClick={e => setEditMode(!editMode)}
                >
                  Save
                </Button>
              </Grid>
              <Grid xs={12} item>
                {false && <Link color="primary" href="#" onClick={preventDefault}>
                  Reset info back to original 
                </Link>}
              </Grid>
            </Grid>
        </Box>
        </ReactCardFlip>
            <Grid xs={12} item>
            <Box sx={{display:'flex', flexDirection:'row', alignItems:'start', justifyContent:'space-between', flexGrow: 1}}>
            <Typography display='inline' sx={{color:'black'}} variant="subtitle1">
              Photos and Videos
            </Typography>
                    <Button color="warning" disabled={tree.media.length==0} variant="contained" onClick={e => setEditPhotoMode(!editPhotoMode)}>{editPhotoMode ? 'Done' : 'Edit Photos'}</Button>
            </Box>
             {tree.media.length!=0 && <ImageList cols={3} sx={{pt:0, mb:3}}>
                {tree.media.map((media, mediaIndex) => (
                  <Box key={mediaIndex} sx={{display:'flex', flexGrow:1, flexDirection:'column',alignItems:'center', justifyContent:'center'}}>
                  <ImageListItem key={mediaIndex} onClick={() => handleViewMedia(mediaIndex)}>
                    <Box component='img' sx={{width: '100%', borderRadius:3}} src={media} alt={`image${mediaIndex}`} loading="lazy" />
                  </ImageListItem>
                    {editPhotoMode &&<Button color="secondary" variant="text" onClick={e => deleteMedia(mediaIndex)}>Remove</Button>}
              </Box>
                ))}
              </ImageList>
              }
            {tree.media.length==0 && <Grid sx={{display:'flex', flexDirection:"row", alignItems:"center", justifyContent:"center"}}  xs={12} item>
              <Box sx={{mt: 2, mx:0, height: 100, width: '33%', borderStyle: 'dotted', borderRadius: 5, borderColor: '#aaa', borderWidth: '3px'}}/>
              <Box sx={{mt: 2, mx:1, height: 100, width: '33%', borderStyle: 'dotted', borderRadius: 5, borderColor: '#aaa', borderWidth: '3px'}}/>
              <Box sx={{mt: 2, mx:0, height: 100, width: '33%', borderStyle: 'dotted', borderRadius: 5, borderColor: '#aaa', borderWidth: '3px'}}/>
            </Grid>
            }
            </Grid>
    </React.Fragment>
  );
}
